import Axios from "axios";
import { base_url1, base_url2 } from "../../constants";
// -------------- Get The List of APIs ------------------------- //
export const getAllApplications = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/get_loan_applications`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//--------------- Get Dashboard JSON ----------------------//
export const getDashboardData = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/dashboard_info_json`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// --------------- Get Application By ID ----------------------------//
export const getApplication = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_loan_applications_by_id/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

// --------------- Get Disbursement Details ----------------------------//
export const getDisbursementDetails = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/disbursal/disbursement-details/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

// --------------- Get Transaction Details ----------------------------//
export const getTransactionDetails = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = {
      Authorization: bearer_token,
    };
    const response = await Axios.get(
      `${base_url2}/disbursal/getDisbursementStatus/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

// --------------- Get URL for downloads ----------------------------//
export const getDownloadUrl = async (key) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = {
      Authorization: bearer_token,
    };
    const response = await Axios.get(
      `${base_url2}/cms/downloadHashedFile/${key}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

// --------------- Submit Disbursal of Loan ----------------------------//
export const submitDisbursal = async (loanId, remarks) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearer_token,
    };
    const response = await Axios.post(
      `${base_url2}/disbursal/disburseLoan`,
      {
        loan_id: loanId,
        remarks: remarks,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//-----------------Reports --------------//
export const getReports = async (timeframe, startdate, enddate, filter) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/generate_reports`,
      {
        timeframe: timeframe,
        startdate: startdate,
        enddate: enddate,
        filter: filter,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------Update Reports--------------//
export const updateReportCMS = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/reporting_table_update`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//--------------------Get Final Decision----------------------------------------------//
export const getFinalDecision = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_cms_pricing_final_decision_by_loan_id/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-----------------Post Final Decision --------------------------//
export const postFinalDecision = async (finalDecision1) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/create_modify_cms_pricing_final_decision_by_loan_id`,
      finalDecision1,
      { headers }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------Get Email-----------------------------------//
export const getEmail = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/get_email/${loanId}`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-----------------send Repayment Schedule ----------------//
export const sendRepaymentSchedule = async (reduxData) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/send_repayment_schedule_email/${reduxData}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------ Company Confirmation Mail -------------------//
export const companyConfirmation = async (reduxData, userEmail) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/create_modify_cms_pricing_final_decision_by_loan_id`,
      {
        loan_id: reduxData,
        email_id: userEmail,
      },
      { headers }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------- Get BRE Decision Table ----------------//
export const getBREDecision = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/get_bre_result/${loanId}`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------- get Financial Records ---------------------//
export const getCMSFinancialRecord = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_cms_financial_record/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------- get CMS documents ---------------------//
export const getCMSDocuments = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_cms_documents/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//--------------- Upload CMS Documents ------------------------------//
export const uploadCMSDocuments = async (data) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(`${base_url2}/cms_uploadFile`, data, {
      headers,
    });
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------- Get Bank statement Record ----------------------//
export const getBankStatementDocument = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_bank_statement_document_record/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- Get bank statement 360 ----------------//
export const getBankStatementDocumentFin360 = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_fin360_bank_statement_report_master/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------Penny Drop -----------------------------//
export const pennyDrop = async (loanID, ifsc_code, account_number) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/penny-drop`,
      {
        loan_id: loanID,
        ifsc: ifsc_code,
        account_number: account_number,
        name: "",
        mobile: "",
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------- Get GST information -----------------//
export const getGSTInformation = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_gstn_details/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------------------- Send Initiate Verification Email -------------------//
export const sendInitiateVerificationMail = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/send_initiate_verification_email/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------------------- Get CMS verification by loan ID ------------------//
export const getInitiateVerification = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_cms_verification_by_loan_id/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//--------------------------- Create Modify CMS verification records ---------------//
export const createModifyCMSRecords = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/create_modify_cms_verification`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    throw new Error(error);
  }
};
//------------------- external agency send mail---------------//
export const initiateExternalAgency = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/initiate_verification_email`,
      {
        loan_id: loanID,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET personal Discussion ----------------//
export const getPersonalDiscussion = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_cms_personal_discussion/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- Post Personal Discussion --------------------------//
export const CreateModifyPD = async (loanID, comment) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/create_modify_cms_personal_discussion`,
      {
        id: 0,
        loan_id: loanID,
        description: comment,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET personal interview ----------------//
export const getPersonalInterview = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_cms_personal_interview/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- Post Personal Interview --------------------------//
export const CreateModifyPI = async (loanID, comment, missing_documents) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/create_modify_cms_personal_interview`,
      {
        id: 0,
        loan_id: loanID,
        description: comment,
        missing_documents: missing_documents,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------- Get disbursement checklist -----------------------//
export const getDisbursementChecklist = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_checklist_disbursement/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------- Get Generate Disbursement -----------------------------//
export const getGenerateDisbursement = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/get_generate_disbursement/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------------------- Create Modify Disbursement -----------------------------//
export const createModifyGenerateDisbursement = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/create_modify_generate_disbursement`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------------------- Update Disbursement ------------------------//
export const createModifyUpdateDisbursement = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/create_modify_update_disbursement`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------- Get Update Disbursement ------------------------------------//
export const getUpdateDisbursement = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_update_disbursement/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------------------- Get Collateral verification record ------------------------//
export const getCollateralVerificationRecord = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_cms_collateral_record/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------------Create Modify Collateral Record ------------------------------------//
export const createModifyCollateralRecord = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/create_modify_cms_collateral_record`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------Get External Agency Mail ---------------------//
export const getExternalAgencyMail = async (loan_id) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/collateral_get_info`,
      {
        loan_id: loan_id,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------ Post Info ------------------------------//
export const getCollateralDetails = async (loan_id) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/collateral_post_info`,
      {
        loan_id: loan_id,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------------------ Get Collateral Verification Email------------------//
export const sendCollateralVerificationEmail = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/send_collateral_verification_email/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------------Update Collateral Document ---------------------//
export const updateCollateralDocument = async (
  reduxData,
  legalCollateral,
  technicalCollateral
) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/update_collateral_doc`,
      {
        loan_application_id: reduxData,
        db_data: {
          legal: legalCollateral,
          technical: technicalCollateral,
        },
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------------------  RE Run BRE ------------------------------//
export const rerunBRE = async (reduxData, accountUID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/async_BRE_execution_by_loan_application_id`,
      {
        LoanApplicationId: reduxData,
        account_uid: accountUID,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------------ Settings -----------------------------------//
//---------------------- Create LDAP user -------------------------------------//
export const modifyUserInLDAP = async (json) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(`${base_url1}/ldap_user_update`, json, {
      headers,
    });
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------------- Get DLA users ------------------------------//
export const getLDAPusers = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url1}/ldap_get_dla_users`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------Delete Users------------------//
export const deleteUserInLDAP = async (id, role, table) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/ldap_user_delete`,
      {
        id: id,
        role_name: role,
        table: table,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------ ADD user ------------------------------//
export const addUserInLDAP = async (json) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(`${base_url1}/ldap_user_create`, json, {
      headers,
    });
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------------- Get CMS users -----------------------//
export const getLDAPusersCMS = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url1}/ldap_get_cms_users`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------------ LOGOUT API -------------------------//
export const logout = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(`${base_url1}/logout`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//--------------------------- Get Video for Video KYC-----------------------------//
export const videoKYC = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_video_kyc_from_azure/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------Get Disbursement MEmo------------------//
export const getDisbursementMemo = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/disbursement_makepdf`,
      {
        loan_id: loanID,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------- get Technical Verification Details ------------------//
export const getTechnicalVerification = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_collateral_legal_records`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------------------- Post Legal Details -----------------//
export const postLegalCollateral = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/update_legal_record`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------- Post technical collateral-----------------------------//
export const postTechnicalCollateral = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/update_collateral_record`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------ Get Technical and Legal Collateral for the CMS manager ----------------//
export const getAllCollateralVerifications1 = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_coll_legal_records/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- Update Basic Information ---------------------------//
export const updateBasicDetails = async (
  loanId,
  personalDetails,
  companyDetails,
  directorDetails,
  guarantorDetails
) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/update_loan_applications_by_id/${loanId}`,
      {
        company_details: companyDetails,
        company_director_details: directorDetails,
        loan_guarantor: guarantorDetails,
        loan_personal_info: personalDetails,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-----------------Post collateral Information  --------------------------//
export const postCollateralDetails = async (details) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/create_modify_collateral_details`,
      details,
      { headers }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------- Get Cibil API ------------------------//
export const getCibilResponse = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_cibil_by_loanid/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------- Get CSV reports  API ------------------------//
export const getCSVReports = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_loan_application_csv/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------------- Get Cibil API ------------------------//
export const getCibil = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_cibil_derived_variables/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------- Post Reset Password ---------------//
export const resetPasswordChange = async (data, sessionId) => {
  try {
    const response = await Axios.post(`${base_url1}/reset_password`, {
      email_id: data.username,
      current_password: data.oldPassword,
      new_password: data.password,
      user_captcha: data.user_captcha,
      session_id: sessionId,
    });
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error?.response?.data?.response_message);
  }
};
//------------- Post Forgot Password ---------------//
export const forgotPasswordChange = async (email_or_phone) => {
  const body = {
    email_or_phone: email_or_phone,
  };
  try {
    const response = await Axios.post(
      `${base_url1}/cms/auth/forgot-password/initiate`,
      body
    );
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response) {
      return response?.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    } else {
      throw new Error(error?.response);
    }
  }
};
//------------- Post OTP Validation Forgot Password ---------------//
export const forgotPasswordOtpValidateChange = async (email_or_phone, otp) => {
  const body = {
    email_or_phone: email_or_phone,
    otp: otp,
  };
  try {
    const response = await Axios.post(
      `${base_url1}/cms/auth/forgot-password/otp-verification`,
      body
    );
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response) {
      return response?.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    } else {
      throw new Error(error?.response);
    }
  }
};
//------------- Post Forgot Password Change ---------------//
export const forgotPasswordChangetoNew = async (
  email_or_phone,
  new_password,
  user_captcha,
  session_id
) => {
  const body = {
    email_or_phone: email_or_phone,
    new_password: new_password,
    user_captcha: user_captcha,
    session_id: session_id,
  };
  try {
    const response = await Axios.post(
      `${base_url1}/cms/auth/forgot-password`,
      body
    );
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response) {
      return response?.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response?.data;
    } else {
      throw new Error(error?.response);
    }
  }
};
//--------------------------- Edit Credit Information ---------------//
export const updateCreditInfo = async (item) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/update_cibil_derived_variables`,
      item,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//--------------------------- Rehit Cibil ---------------//
export const rehitCibil = async (loan_id) => {
  const body = {
    loan_id: loan_id,
  };
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    // const response = Axios.post(`${base_url1}/rehit-cibil`, body,
    const response = Axios.post(`${base_url2}/rehit-cibil`, body, {
      headers,
    });
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error(error);
    }
  }
};

//----------------- Get BRE results ------------------------//
export const getBREFraudResults = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_bre_fraud_results/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- KYC image Loading--------------------//
export const getIdentificationCard = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_customer_file_upload/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//------------------------- Upload Identification Card   --------------------

export const uploadIdentificationCard = async (data) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/customer_file_upload`,
      data,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-----------------Modify Repayment Schedule ----------------//
export const modifyRepaymentSchedule = async (reduxData) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/create_modify_repayment_schedule/${reduxData}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- Passport image Loading--------------------//
export const getPassport = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_customer_passport/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// --------------- Get List of documents ----------------------------//
export const getListofDocuments = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/getAllSignedDocs/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET question and Answers for PD ----------------//
export const getQuestionAndAnswer = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/pd/getPersonalDiscussionQnA/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------- submit pd question answers ---------------//
export const submitPDAnswers = async (loanId, data) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/pd/submitPersonalDiscussionQnA/${loanId}`,
      data,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET Document for PD screen ----------------//
export const getDocuments = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/pd/getAllDocuemnts/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ Send BRE run Mail----------------//
export const sendBreMail = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/send_BRE_initiation_email/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET Bank NAmes ----------------//
export const getBankNames = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/masters/getAllFinbitBankNames`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET Reject Reason ----------------//
export const getrejectReason = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_reject_reasons/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ GET Bank NAmes ----------------//
export const refreshBankStatement = async (id) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/refresh_bank_statement_reports/${id}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ ReRUn ----------------//
export const BREreRun = async (id) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/rerun_bre_cms/${id}`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------Disbursement -----------------------------//
export const readyforDisbursement = async (
  loanID,
  pdc_collected,
  mitc_done
) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/ready_for_disbursement`,
      {
        loan_id: loanID,
        pdc_collected: pdc_collected,
        mitc_done: mitc_done,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
// ------------------ Get Repayment Schedule ----------------//
export const getrepaymentSchedule = async (id) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url1}/cms_get_emi_details/${id}`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------- PAN image Loading--------------------//
export const getPAN = async (loanID) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/get_customer_pan/${loanID}`,
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//-------------------------- Branch Master ------------------------//

export const getAllBranches = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url1}/masters/getAllBranch`, {
      headers,
    });
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response.data?.response_data;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//---------------------------- Loan categories Master ------------------------ //

export const getAllLoanCategories = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/masters/getAllLoanCategories`,
      { headers }
    );
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response.data?.response_data;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//--------------------------- Generate report api --------------------------------//

export const generateReport = async (
  filtered_branches,
  filtered_loan_categories,
  filtered_channel_names,
  start_date,
  end_date,
  dimension,
  page_no
) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    if (dimension) {
      dimension = [dimension];
    } else {
      dimension = [];
    }
    const data = {
      filter: {
        branch: filtered_branches,
        loan_product: filtered_loan_categories,
        channel_name: filtered_channel_names,
        pincode: [],
        start_date: start_date,
        end_date: end_date,
        month: [],
        year: [],
      },
      dimensions: dimension,
    };
    const response = await Axios.post(
      `${base_url1}/cms/getReport/${page_no}`,
      data,
      { headers }
    );
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response.data?.response_data;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const getDisbursementChecklistNew = async (loanId) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url2}/disbursal/pre-disbursal-checklist/${loanId}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//----------- Submit the checklist ------------//
export const submitChecklist = async (loanId, pre_disbursal_checklist) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url2}/disbursal/pre-disbursal-checklist`,
      {
        pre_disbursal_checklist,
        loanId,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------Send Bulk Message -----------------------------//
export const sendBulkMessages = async (data, template) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/whatsapp/sendBulkMessage`,
      {
        bulk_user_base: data,
        template_name: template,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//---------------------------- User Channel Master ------------------------ //

export const getAllUserChannelMaster = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/masters/getAllUserChannels`,
      { headers }
    );
    if (response.status === 401) {
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response.data?.response_data;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------------------- Get CMS verification by loan ID ------------------//
export const getEligibileCustomer = async (pageNo) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(
      `${base_url1}/topup/getEligibileCustomer/${pageNo}`,
      {
        headers,
      }
    );

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//-------------------------------- Get CMS verification by loan ID ------------------//
export const getOffersSent = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url1}/topup/getOffersSent`, {
      headers,
    });

    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};
//---------------------Send Bulk Message -----------------------------//
export const sendBulkOffers = async (data) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.post(
      `${base_url1}/topup/sendOffer`,
      {
        customers: data,
      },
      {
        headers,
      }
    );
    if (response.status === 401) {
      // Handle unauthorized error
      throw new Error("Unauthorized");
    }
    if (response.data) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    throw new Error(error);
  }
};

//-------------------------- CHECKING BALANCE ---------------------
export const getBalance = async () => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = await Axios.get(`${base_url2}/disbursal/balance-check`, {
      headers,
    });
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    // throw new Error(error);
  }
};
//-------------------------- PENNY DROP ---------------------
export const pennyDropCheck = async (loan_id) => {
  const body = {
    loan_id: loan_id,
  };
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearer_token,
    };
    const response = await Axios.post(
      `${base_url2}/disbursal/penny-drop`,
      body,
      {
        headers,
      }
    );
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error(error);
    }
  }
};
//-------------------------- CONFIRM DISBURSEMENT ---------------------
export const confirmDisbursementApi = async (
  loan_id,
  remarks,
  modeOfTransfer
) => {
  const body = {
    loan_id: loan_id,
    remark: remarks,
  };
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearer_token,
    };
    const response = await Axios.post(
      `${base_url2}/disbursal/disburse/${modeOfTransfer}`,
      body,
      {
        headers,
      }
    );
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error(error);
    }
  }
};

//--------------------------DISBURSEMENT STATUS---------------------
export const disbursementStatus = async (loan_id) => {
  const body = {
    loan_id: loan_id,
  };
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = {
      "Content-Type": "application/json",
      Authorization: bearer_token,
    };
    const response = await Axios.post(
      `${base_url2}/disbursal/disbursement-status`,
      body,
      {
        headers,
      }
    );
    if (response) {
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error(error);
    }
  }
};
//--------------------------- Rehit BRE Credit Info ---------------//
export const rehitBRE = async (loan_id, send_email, update_records) => {
  const body = {
    loan_id: loan_id,
    send_email: send_email,
    update_records: update_records,
  };
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    // const response = Axios.post(`${base_url1}/rerun-bre-1`, body,
    const response = Axios.post(`${base_url2}/rerun-bre-1`, body, {
      headers,
    });
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error(error);
    }
  }
};
//--------------------------- Update Bank Details ---------------//
export const updateDetails = async (loan_id, editData) => {
  try {
    const bearer_token = "Bearer " + sessionStorage.getItem("Access_Token");
    const headers = { Authorization: bearer_token };
    const response = Axios.post(
      `${base_url2}/update_bank_details/${loan_id}`,
      editData,
      {
        headers,
      }
    );
    if (response) {
      return response;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error(error);
    }
  }
};
